.rmsc {
  --rmsc-main: none;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 20px;
  --rmsc-radius: 9px !important;
  --rmsc-h: 60px !important;
  font-size: 1rem !important;
}

.rmsc * {
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.rmsc .gray {
  color: #8e8e8e;
}

.rmsc .dropdown-content {
  position: absolute;
  z-index: 999 !important;
  top: 100%;
  width: 100%;
  padding-top: 8px;
  font-family: "Silka";
  font-size: 14px;
}

.rmsc .dropdown-content .panel-content {
  overflow: hidden;
  border-radius: none;
  background: var(--rmsc-bg);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
}

.rmsc .dropdown-container {
  position: relative;
  outline: 0;
  background-color: var(--rmsc-bg);
  border: 1px solid var(--rmsc-border);
  border-radius: var(--rmsc-radius);
}

.rmsc .dropdown-container[aria-disabled="true"]:focus-within {
  box-shadow: var(--rmsc-gray) 0 0 0 1px;
  border-color: none;
}

.rmsc .dropdown-container:focus,
.rmsc .dropdown-container:focus-visible,
.rmsc .dropdown-container:focus-within {
  outline: none !important;
  box-shadow: none !important;
  border-color: #808080 !important;
}
.rmsc .dropdown-container *:focus {
  outline: none !important;
  box-shadow: none !important;
}

.rmsc .dropdown-heading {
  position: relative;
  padding: 0 var(--rmsc-p);
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--rmsc-h);
  cursor: default;
  outline: 0;
  background-color: #f6f7f7;
  border-radius: 9px !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  padding: 5px;
}

.rmsc .clear-selected-button {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  display: flex;
}

.rmsc .options {
  max-height: 260px;
  overflow-y: auto;
  margin: 0;
  padding-left: 0;
}

.rmsc .options li {
  list-style: none;
  margin: 0;
}

.rmsc .select-item {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: var(--rmsc-p);
  outline-offset: -1px;
  outline-color: var(--rmsc-primary);
}

.rmsc .select-item:hover {
  background: var(--rmsc-hover);
}

.rmsc .select-item.selected {
  background: #f1f3f5 !important;
  color: #000;
}

.rmsc .no-options {
  padding: var(--rmsc-p);
  text-align: center;
  color: var(--rmsc-gray);
}

.rmsc .search {
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--rmsc-border);
}

.rmsc .search input {
  background: none;
  height: var(--rmsc-h);
  padding: 0 var(--rmsc-p);
  width: 100%;
  outline: 0;
  border: 0;
  font-size: 1em;
}

.rmsc .search input:focus {
  background: var(--rmsc-hover);
}

.rmsc .search-clear-button {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: none;
  border: 0;
  padding: 0 calc(var(--rmsc-p) / 2);
}

.rmsc .search-clear-button [hidden] {
  display: none;
}

.rmsc .item-renderer {
  display: flex;
  align-items: baseline;
}

.rmsc .options {
  display: flex;
  flex-direction: column !important;
  gap: 0px;
}

.rmsc .options li {
  width: 100%;
}

.rmsc .dropdown-content {
  z-index: 9999 !important;
}

.rmsc .item-renderer input {
  margin: 0;
  margin-right: 10px;
  accent-color: #4a9cb9 !important;
  position: relative;
  height: 1.15rem;
  width: 1.15rem;
  cursor: pointer;
  appearance: none;
  border-radius: 0.375rem;
  border: 1px solid #cbd5e0;
  transition: all 0.3s ease;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.rmsc .item-renderer input::before {
  content: "✔";
  /* Checkmark symbol */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  /* Checkmark color */
  font-size: 0.9rem;
  /* Adjust the size of the checkmark */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;
}

.rmsc .item-renderer input:checked::before {
  opacity: 1;
  /* Show the checkmark when the checkbox is checked */
}

.rmsc .item-renderer input:checked {
  border-color: #000;
  /* Adjust the border color when checked */
}

.rmsc .item-renderer.disabled {
  opacity: 0.5;
}

.rmsc .spinner {
  animation: rotate 2s linear infinite;
}

.rmsc .spinner .path {
  stroke: var(--rmsc-border);
  stroke-width: 4px;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

::placeholder {
  color: red;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: red;
}

.rmsc.special-dropdown .item-renderer input {
  display: none;
}

.corner-ribbon .white-block {
  display: block;
  background: white;
  padding: 3rem;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.7);
  position: relative;
  top: 50px;
  left: 50%;
  margin-left: -100px;
}

/* ----- Ribbon Container ---------------------------------------*/
.corner-ribbon .ribbon-container {
  background: transparent;
  height: 130px;
  width: 130px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}

/* ----- Top Curl ---------------------------------------*/
.corner-ribbon .ribbon-container:before {
  content: "";
  display: block;
  -webkit-border-radius: 8px 8px 0px 0px;
  border-radius: 8px 8px 0px 0px;
  width: 36px;
  height: 8px;
  position: absolute;
  left: 65px;
  background: #c0ff06;
  background: linear-gradient(to left, #c0ff06 1%, #98cc04 45%);
}

/* ----- Right Curl ---------------------------------------*/
.corner-ribbon .ribbon-container:after {
  content: "";
  display: block;
  -webkit-border-radius: 8px 0px 0px 8px;
  border-radius: 8px 0px 0px 8px;
  width: 8px;
  height: 36px;
  position: absolute;
  left: 0px;
  top: 65px;
  background: #98cc04;
  background: linear-gradient(to bottom, #98cc04 55%, #c0ff06 99%);
}

/* ----- Ribbon ---------------------------------------*/
.corner-ribbon .ribbon {
  width: 130px;
  height: 27px; /* Slightly thinner */
  position: relative;
  top: 20px;
  left: -30px;
  z-index: 1;
  overflow: hidden;
  transform: rotate(-48deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  background: #c0ff06;
  background: linear-gradient(to right, #a8e304 0%, #c0ff06 51%, #a8e304 100%);
}

/* ----- Top Glare ---------------------------------------*/
.corner-ribbon .ribbon:before {
  content: "";
  display: block;
  height: 50px;
  width: 50px;
  position: relative;
  top: -10px;
  left: 20px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

/* ----- Right Clear ---------------------------------------*/
.corner-ribbon .ribbon:after {
  content: "";
  display: block;
  height: 50px;
  width: 10px;
  position: relative;
  bottom: 60px;
  left: 106px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.corner-ribbon .ribbon-text {
  color: black; /* Text color set to black */
  font-weight: 650;
  font-size: 12px;
  font-family: "Silka";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  white-space: nowrap;
}
