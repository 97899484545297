.univer-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Hide the menubar */
:global(.univer-menubar) {
  display: none;
}

.univer-sheet-bar {
  display: none !important;
}

.univer-workbench-container-sidebar {
  height: 400px !important;
}

.univer-name-ranges input {
  font-size: 14px !important;
}
